import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import { LogoDark } from "../global/logo";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      socialLinks: sanityContact {
        socialLinks {
          instagram
          linkedIn
          twitter
          youtube
          facebook
        }
      }
    }
  `);

  return (
    <>
      <div className="custom-footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 mx-auto">
              <div className="brand-section">
                <div className="navbar-brand text-center mb-5">
                  <div className={`brand-logo`}>{LogoDark}</div>
                </div>
                <div className="d-flex"></div>
                <p className="text-small mb-5 text-center text">
                  Built on the principles of social justice, equity and empathy,
                  Sinhayana Foundation believes in creating deep impact within
                  communities that are systematically marginalized.
                </p>
                <div className="d-flex justify-content-center social-links">
                  {data.socialLinks.socialLinks.instagram && (
                    <a
                      className="btn mx-2"
                      href={data.socialLinks.socialLinks.instagram}
                    >
                      <i className="bi bi-instagram"></i>
                    </a>
                  )}
                  {data.socialLinks.socialLinks.facebook && (
                    <a
                      className="btn mx-2"
                      href={data.socialLinks.socialLinks.facebook}
                    >
                      <i className="bi bi-facebook"></i>
                    </a>
                  )}
                  {data.socialLinks.socialLinks.linkedIn && (
                    <a
                      className="btn mx-2"
                      href={data.socialLinks.socialLinks.linkedIn}
                    >
                      <i className="bi bi-linkedin"></i>
                    </a>
                  )}
                  {data.socialLinks.socialLinks.twitter && (
                    <a
                      className="btn mx-2"
                      href={data.socialLinks.socialLinks.twitter}
                    >
                      <i className="bi bi-twitter"></i>
                    </a>
                  )}
                  {data.socialLinks.socialLinks.youtube && (
                    <a
                      className="btn mx-2"
                      href={data.socialLinks.socialLinks.youtube}
                    >
                      <i className="bi bi-youtube"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ul className="bottom-nav nav flex-row justify-content-center my-4">
            <li className="nav-item">
              <Link to="/contact" className="nav-link">
                Contact
              </Link>
            </li>
            <li className="nav-item">
              <Link href="/#about" className="nav-link">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/#programs" className="nav-link">
                Programs
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/terms-and-conditions" className="nav-link">
                Terms & Conditions
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/privacy-policy" className="nav-link">
                Privacy Policy
              </Link>
            </li>
          </ul>
          <hr />
          <p className="text-center text-small text-secondary">
            &copy; {new Date().getFullYear()} Sinhayana Foundation. All Rights
            Reserved.
          </p>
        </div>
      </div>

      <style jsx>{`
        .custom-footer {
          padding: 5rem 0rem 1rem 0rem;
        }

        .custom-footer .text-small {
          font-size: 0.875rem;
          color: #505050;
        }

        .custom-footer .nav-link {
          font-size: 0.875rem;
          color: #808080;
          text-decoration: underline;
        }

        .custom-footer .nav-link:hover {
          color: #000000;
        }

        .custom-footer .social-links a {
          color: #505050;
        }

        .custom-footer .social-links a:hover {
          color: #000000;
        }
      `}</style>
    </>
  );
};

export default Footer;
