import React from "react";
import { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { LogoLight } from "../global/logo";
import * as styles from "../../styles/navs/navbar.module.scss";

const Navbar = (props) => {
  const data = useStaticQuery(graphql`
    query navInitiativesQuery {
      initiatives: allSanityInitiative {
        edges {
          node {
            slug {
              current
            }
            title
          }
        }
      }
    }
  `);

  const [navbarExpanded, setNavbarExpanded] = useState(false);

  return (
    <>
      <div
        className={`navbar custom-navbar navbar-expand-lg ${styles.customNavbar} align-items-start align-items-lg-center`}
        style={{
          backgroundColor: navbarExpanded && "#000000",
          height: navbarExpanded && "100vh",
          transition: "0.3s all",
        }}
      >
        <div className="container">
          <Link to="/">
            <div className="navbar-brand">
              <div className={`brand-logo ${styles.brandLogo}`}>
                {LogoLight}
              </div>
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setNavbarExpanded(!navbarExpanded)}
          >
            <i
              className={`bi ${navbarExpanded ? "bi-x" : "bi-list"} text-light`}
              style={{ fontSize: navbarExpanded && "2.4rem" }}
            ></i>
          </button>
          <div
            className={`collapse navbar-collapse my-4 my-lg-0 ${
              navbarExpanded && "show"
            }`}
            id="navbarContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item my-2 my-lg-0">
                <div className="dropdown">
                  <a
                    className="btn dropdown-toggle nav-link"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Initiatives
                  </a>

                  <ul
                    className="dropdown-menu dropdown-menu-dark my-3 my-lg-0"
                    aria-labelledby="dropdownMenuLink"
                  >
                    {data.initiatives.edges.map((initiative, index) => (
                      <li key={initiative.node.title}>
                        <Link
                          className="dropdown-item my-1"
                          to={
                            initiative.node.slug &&
                            `inittiatives/${initiative.node.slug.current}`
                          }
                        >
                          {initiative.node.title}
                        </Link>
                        {index !== data.initiatives.edges.length - 1 && (
                          <hr
                            className="my-0 mx-3"
                            style={{ height: "0.5px" }}
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
              <hr
                className="d-block d-lg-none bg-light my-0"
                style={{ height: "0.5px" }}
              />
              <li className="nav-item my-2 my-lg-0">
                <Link className="nav-link" to="/about/">
                  About
                </Link>
              </li>

              <hr
                className="d-block d-lg-none bg-light my-0"
                style={{ height: "0.5px" }}
              />
              <li className="nav-item my-2 my-lg-0">
                <Link className="nav-link" to="#">
                  Blog
                </Link>
              </li>

              <hr
                className="d-block d-lg-none bg-light my-0"
                style={{ height: "0.5px" }}
              />
              <li className="nav-item my-2 my-lg-0">
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li>

              <hr
                className="d-block d-lg-none bg-light my-0"
                style={{ height: "0.5px" }}
              />
              <li className="nav-item my-2 my-lg-0 d-none d-lg-block">
                <Link
                  className="nav-link btn btn-outline-light rounded px-3 py-2"
                  to="/donate"
                >
                  Donate
                </Link>
              </li>
              <li className="nav-item my-lg-0 d-flex justify-content-end d-lg-none mt-4">
                <Link
                  className="nav-link btn btn-light rounded px-3 py-2 text-dark w-100"
                  to="/donate"
                  style={{ fontWeight: "500" }}
                >
                  Donate
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <style jsx>
        {`
          .custom-navbar .navbar-toggler-icon {
            font-size: 1.75rem;
          }

          .custom-navbar .nav-item {
            margin: auto 0.75rem;
            text-align: right;
          }

          .custom-navbar .nav-link {
            text-decoration: none;
            color: #ffffff;
            opacity: 0.9;
            font-weight: 300;
          }

          .custom-navbar .nav-link:hover {
            opacity: 1;
          }

          .custom-navbar .nav-link.btn-outline-light:hover {
            color: #000000;
            font-weight: 300;
          }

          .custom-navbar .nav-link i {
            font-size: 0.75rem;
          }

          .custom-navbar .custom-btn {
            padding: 0.5rem 1rem;
          }

          .custom-navbar .offcanvas .nav-item {
            margin: 0.25rem 0rem;
          }

          .custom-navbar .offcanvas .nav-link {
            font-size: 1.5rem;
            color: #505050;
          }

          .custom-navbar .offcanvas .nav-link:hover {
            color: #000000;
          }

          .custom-navbar .offcanvas .custom-btn {
            font-size: 1.25rem;
          }

          .custom-navbar .offcanvas .offcanvas-header {
            border-bottom: 1px solid #505050;
          }

          .custom-navbar .dropdown-menu {
            background-color: #000000;
            border: 0.5px solid #505050;
            top: 3rem;
          }

          .custom-navbar .dropdown-menu .dropdown-item {
            font-size: 0.875rem;
            font-weight: 300;
          }

          @media only screen and (max-width: 768px) {
            .custom-navbar .offcanvas-end {
              width: 100%;
            }

            .custom-navbar .navbar-toggler i {
              font-size: 1.8rem;
            }

            .custom-navbar .nav-link {
              font-size: 1.125rem;
            }
          }
        `}
      </style>
    </>
  );
};

export default Navbar;
